/**
 * Created by crisfuller on 2016/04/10.
 */

jQuery(document).ready(function(){
    jQuery(".numeric-only").keydown(function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        if (jQuery.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
                // Allow: Ctrl+A
            (e.keyCode == 65 && e.ctrlKey === true) ||
                // Allow: Ctrl+C
            (e.keyCode == 67 && e.ctrlKey === true) ||
                // Allow: Ctrl+X
            (e.keyCode == 88 && e.ctrlKey === true) ||
                // Allow: home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    });
});

function addSuccessBg( obj ){
    if( jQuery( obj ).hasClass("bg-error") )
    {
        jQuery( obj ).removeClass("bg-error");
    }
    jQuery( obj ).addClass("bg-success");
}
function addErrorBg( obj ){
    if( jQuery( obj ).hasClass("bg-success") )
    {
        jQuery( obj ).removeClass("bg-success");
    }
    jQuery( obj ).addClass("bg-error");
}
function setObjHTML( obj, val ){
    jQuery( obj ).html( val );
}

function setObjectHTML( object, type, value ){
    var selector;
    if( (type != "id" && type != "class") || object.length <= 0 ){
        return false;
    }
    selector = getSelector( object, type );

    jQuery(selector).html( value );
    return true;
}

function clearSelect( object, type, removeAll ){
    var selector;
    if( (type != "id" && type != "class") || object.length <= 0 ){
        return false;
    }
    selector = getSelector( object, type );

    if( removeAll )
        jQuery( selector).empty();
    else
        jQuery( selector).find("option:gt(0)").remove();

    return true;
}

function resetObject( object, type, value ){

    var selector;
    if( (type != "id" && type != "class") || object.length <= 0 ){
        return false;
    }
    selector = getSelector( object, type );

    jQuery(selector).val( value );
    return true;
}

function showObject( object, type ){

    var selector;
    if( (type != "id" && type != "class") || object.length <= 0 ){
        return false;
    }
    selector = getSelector( object, type );
    if( jQuery( selector ).hasClass("hidden") )
        jQuery( selector ).removeClass("hidden");
    else {
        if( jQuery( selector).css( "display" ) == 'none' ){
            jQuery( selector ).show();
        }
    }

    return true;
}

function hideObject( object, type ){

    var selector;
    if( (type != "id" && type != "class") || object.length <= 0 ){
        return false;
    }
    selector = getSelector( object, type );
    if( ! jQuery( selector ).hasClass("hidden") )
        jQuery( selector ).addClass("hidden");
    else {
        if( jQuery( selector).css( "display" ) != 'none' ){
            jQuery( selector ).hide();
        }
    }


    return true;
}

function getObject( object, type ){
    var selector;
    if( (type != "id" && type != "class") || object.length <= 0 ){
        return null;
    }
    selector = getSelector( object, type );
    return jQuery( selector );
}

function getObjectValue( object, type ){
    var selector;
    if( (type != "id" && type != "class") || object.length <= 0 ){
        return null;
    }
    selector = getSelector( object, type );
    return jQuery( selector).val();
}

function getTableRowIndex(x) {
    return x.rowIndex;
}

function getSelector( object, type ){
    if( type == "id" ) selector = "#"+object;
    else selector = "."+object;
    return selector;
}

function convertToSlug(Text)
{
    return Text
        .toLowerCase()
        .replace(/[^\w ]+/g,'')
        .replace(/ +/g,'-')
        ;
}

function escapeHtml(text) {
    var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
    };

    return text.replace(/[&<>"']/g, function(m) { return map[m]; });
}

function convertSpecialCharsToText(text) {
    var map = {
        '&amp;'     : '&',
        '&lt;'      : '<',
        '&gt;'      : '>',
        '&quot;'    : '"',
        '&#039;'    : "'"
    };

    return text.replace(/[&<>"']/g, function(m) { return map[m]; });
}

function getFormattedDate(date) {
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return month + '/' + day + '/' + year;
}

function initMultiSelect(selector){
    jQuery(selector).multiselect({
        enableFiltering: true,
        includeSelectAllOption: true,
        buttonContainer: '<div class="btn-group col-md-12 no-padding" />',
        //buttonClass: 'btn btn-white btn-primary',
        templates: {
            button: '<button type="button" class="multiselect dropdown-toggle btn-block" data-toggle="dropdown"></button>',
            ul: '<ul class="multiselect-container dropdown-menu col-md-12 no-padding"></ul>',
            filter: '<li class="multiselect-item filter"><div class="input-group"><span class="input-group-addon"><i class="fa fa-search"></i></span><input class="form-control multiselect-search" type="text"></div></li>',
            filterClearBtn: '<span class="input-group-btn"><button class="btn btn-default btn-white btn-grey multiselect-clear-filter" type="button"><i class="fa fa-times-circle red2"></i></button></span>',
            li: '<li><a href="javascript:void(0);"><label></label></a></li>',
            divider: '<li class="multiselect-item divider"></li>',
            liGroup: '<li class="multiselect-item group"><label class="multiselect-group"></label></li>'
        }
    });
}

function isInArray(value, array) {
    return array.indexOf(value) > -1;
}

(function () {
    var table = {};

    window.unitConverter = function (value, unit) {
        this.value = value;
        if (unit) {
            this.currentUnit = unit;
        }
    };
    unitConverter.prototype.as = function (targetUnit) {
        this.targetUnit = targetUnit;
        return this;
    };
    unitConverter.prototype.is = function (currentUnit) {
        this.currentUnit = currentUnit;
        return this;
    };

    unitConverter.prototype.val = function () {
        // first, convert from the current value to the base unit
        var target = table[this.targetUnit];
        var current = table[this.currentUnit];
        if (target.base != current.base) {
            throw new Error('Incompatible units; cannot convert from "' + this.currentUnit + '" to "' + this.targetUnit + '"');
        }

        return this.value * (current.multiplier / target.multiplier);
    };
    unitConverter.prototype.toString = function () {
        return this.val() + ' ' + this.targetUnit;
    };
    unitConverter.prototype.debug = function () {
        return this.value + ' ' + this.currentUnit + ' is ' + this.val() + ' ' + this.targetUnit;
    };
    unitConverter.addUnit = function (baseUnit, actualUnit, multiplier) {
        table[actualUnit] = { base: baseUnit, actual: actualUnit, multiplier: multiplier };
    };

    var prefixes = ['Y', 'Z', 'E', 'P', 'T', 'G', 'M', 'k', 'h', 'da', '', 'd', 'c', 'm', 'u', 'n', 'p', 'f', 'a', 'z', 'y'];
    var factors = [24, 21, 18, 15, 12, 9, 6, 3, 2, 1, 0, -1, -2, -3, -6, -9, -12, -15, -18, -21, -24];
    // SI units only, that follow the mg/kg/dg/cg type of format
    var units = ['g', 'b', 'l', 'm'];

    for (var j = 0; j < units.length; j++) {
        var base = units[j];
        for (var i = 0; i < prefixes.length; i++) {
            unitConverter.addUnit(base, prefixes[i] + base, Math.pow(10, factors[i]));
        }
    }

    // we use the SI gram unit as the base; this allows
    // us to convert between SI and English units
    unitConverter.addUnit('g', 'ounce', 28.3495231);
    unitConverter.addUnit('g', 'oz', 28.3495231);
    unitConverter.addUnit('g', 'pound', 453.59237);
    unitConverter.addUnit('g', 'lb', 453.59237);


    window.$u = function (value, unit) {
        var u = new window.unitConverter(value, unit);
        return u;
    };
})();
