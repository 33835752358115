function blockUser( id ){

    if( id <= 0 ){

        /*var msg = "Invalid data provided ";
        addErrorBg( "#myMessageModal .modal-header" );
        setObjHTML( "#myMessageModal h4.modal-title", "Error" );
        setObjHTML( "#myMessageModal div.modal-body", msg );
        jQuery( "#myMessageModal" ).modal();*/
        return;
    }

    showObject('loading', 'id');
    jQuery.ajax({
        type: "POST",
        timeout: timeoutTime,
        cache: false,
        url: '/client/block',
        data: {
            client  : id


        },
        success:function( data ){

            console.log(JSON.stringify(data)+" tota");
            hideObject('loading', 'id');
            var msg = [];
            var i = 0;
            if( data.error_code > 0 ){

                if( data.message.length > 0 ){
                    msg.push("<ul>");
                    for(i=0; i < data.message.length; i++ ){
                        msg.push("<li>"+ data.message[i] + "</li>");
                    }
                    msg.push("</ul>");

                    addErrorBg( "#myMessageModal .modal-header" );
                    setObjHTML( "#myMessageModal h4.modal-title", "Error" );
                    setObjHTML( "#myMessageModal div.modal-body", msg.join('') );
                    jQuery( "#myMessageModal" ).modal();

                }
            }
            else {
                if(data.success ){

                    window.location.reload();
                }
            }

        },
        error: function( xhr, textStatus, errorThrown ){
            hideObject('loading', 'id');
            var msg = "An error occured! "+ ( errorThrown ? errorThrown : xhr.status );
            addErrorBg( "#myMessageModal .modal-header" );
            setObjHTML( "#myMessageModal h4.modal-title", "Error" );
            setObjHTML( "#myMessageModal div.modal-body", msg );
            jQuery( "#myMessageModal" ).modal();
        }
    });
}

function unblockUser( id ){

    if( id <= 0 ){

        /*var msg = "Invalid data provided ";
         addErrorBg( "#myMessageModal .modal-header" );
         setObjHTML( "#myMessageModal h4.modal-title", "Error" );
         setObjHTML( "#myMessageModal div.modal-body", msg );
         jQuery( "#myMessageModal" ).modal();*/
        return;
    }

    showObject('loading', 'id');
    jQuery.ajax({
        type: "POST",
        timeout: timeoutTime,
        cache: false,
        url: '/client/unblock',
        data: {
            client  : id


        },
        success:function( data ){

            console.log(JSON.stringify(data)+" tota");
            hideObject('loading', 'id');
            var msg = [];
            var i = 0;
            if( data.error_code > 0 ){

                if( data.message.length > 0 ){
                    msg.push("<ul>");
                    for(i=0; i < data.message.length; i++ ){
                        msg.push("<li>"+ data.message[i] + "</li>");
                    }
                    msg.push("</ul>");

                    addErrorBg( "#myMessageModal .modal-header" );
                    setObjHTML( "#myMessageModal h4.modal-title", "Error" );
                    setObjHTML( "#myMessageModal div.modal-body", msg.join('') );
                    jQuery( "#myMessageModal" ).modal();

                }
            }
            else {
                if(data.success ){

                    window.location.reload();
                }
            }

        },
        error: function( xhr, textStatus, errorThrown ){
            hideObject('loading', 'id');
            var msg = "An error occured! "+ ( errorThrown ? errorThrown : xhr.status );
            addErrorBg( "#myMessageModal .modal-header" );
            setObjHTML( "#myMessageModal h4.modal-title", "Error" );
            setObjHTML( "#myMessageModal div.modal-body", msg );
            jQuery( "#myMessageModal" ).modal();
        }
    });
}

