function getPromoStores( promo, obj, type, selectType ){
    showObject('loading', 'id');
    jQuery.ajax({
        type: "POST",
        timeout: timeoutTime,
        cache: false,
        url: "/promo/stores",
        data: {
            promo   : promo
        },
        success:function( data ){

            //console.log(JSON.stringify(data));
            hideObject('loading', 'id');
            var msg = [];
            var i = 0;
            if(data.error_code > 0 ){

                if( data.message.length > 0 ){
                    msg.push("<ul>");
                    for(i=0; i < data.message.length; i++ ){
                        msg.push("<li>"+ data.message[i] + "</li>");
                    }
                    msg.push("</ul>");

                    addErrorBg( "#myMessageModal .modal-header" );
                    setObjHTML( "#myMessageModal h4.modal-title", "Error" );
                    setObjHTML( "#myMessageModal div.modal-body", msg.join('') );
                    jQuery( "#myMessageModal" ).modal();

                }
            }
            else {
                if( data.success ){

                    if(type == 'select' ){

                        var selector = obj + " option";
                        //jQuery(selector).not(':eq(0)').remove();
                        jQuery(selector).remove();
                        for(i=0; i < data.result.length; i++ ){
                            jQuery(obj).append('<option value="'+data.result[i].ref+'">'+ data.result[i].name + '</li>');
                            //console.log(JSON.stringify(data.result[i]));
                        }

                        if(selectType == 'select2'){
                            jQuery(obj).select2({
                                allowClear  : true

                            });
                        }
                        if(selectType == 'multi'){
                            jQuery(obj).multiselect('rebuild');
                        }
                    }

                }
            }



        },

        error: function( xhr, textStatus, errorThrown ){
            hideObject('loading', 'id');
            var msg = "An error occured! "+ ( errorThrown ? errorThrown : xhr.status );
            addErrorBg( "#myMessageModal .modal-header" );
            setObjHTML( "#myMessageModal h4.modal-title", "Error" );
            setObjHTML( "#myMessageModal div.modal-body", msg );
            jQuery( "#myMessageModal" ).modal();
        }
    });

}


function getPromoChannels( promo, obj, type, selectType ){
    showObject('loading', 'id');
    jQuery.ajax({
        type: "POST",
        timeout: timeoutTime,
        cache: false,
        url: "/promo/channels",
        data: {
            promo   : promo
        },
        success:function( data ){

            //console.log(JSON.stringify(data));
            hideObject('loading', 'id');
            var msg = [];
            var i = 0;
            if(data.error_code > 0 ){

                if( data.message.length > 0 ){
                    msg.push("<ul>");
                    for(i=0; i < data.message.length; i++ ){
                        msg.push("<li>"+ data.message[i] + "</li>");
                    }
                    msg.push("</ul>");

                    addErrorBg( "#myMessageModal .modal-header" );
                    setObjHTML( "#myMessageModal h4.modal-title", "Error" );
                    setObjHTML( "#myMessageModal div.modal-body", msg.join('') );
                    jQuery( "#myMessageModal" ).modal();

                }
            }
            else {
                if( data.success ){

                    if(type == 'select' ){

                        var selector = obj + " option";
                        //jQuery(selector).not(':eq(0)').remove();
                        jQuery(selector).remove();
                        for(i=0; i < data.result.length; i++ ){
                            jQuery(obj).append('<option value="'+data.result[i].ref+'">'+ data.result[i].name + '</li>');
                            //console.log(JSON.stringify(data.result[i]));
                        }

                        if(selectType == 'select2'){
                            jQuery(obj).select2({
                                allowClear  : true

                            });
                        }
                        if(selectType == 'multi'){
                            jQuery(obj).multiselect('rebuild');
                        }
                    }

                }
            }



        },

        error: function( xhr, textStatus, errorThrown ){
            hideObject('loading', 'id');
            var msg = "An error occured! "+ ( errorThrown ? errorThrown : xhr.status );
            addErrorBg( "#myMessageModal .modal-header" );
            setObjHTML( "#myMessageModal h4.modal-title", "Error" );
            setObjHTML( "#myMessageModal div.modal-body", msg );
            jQuery( "#myMessageModal" ).modal();
        }
    });

}


function getPromoDistricts( promo, obj, type, selectType ){
    showObject('loading', 'id');
    jQuery.ajax({
        type: "POST",
        timeout: timeoutTime,
        cache: false,
        url: "/promo/districts",
        data: {
            promo   : promo
        },
        success:function( data ){

            //console.log(JSON.stringify(data));
            hideObject('loading', 'id');
            var msg = [];
            var i = 0;
            if(data.error_code > 0 ){

                if( data.message.length > 0 ){
                    msg.push("<ul>");
                    for(i=0; i < data.message.length; i++ ){
                        msg.push("<li>"+ data.message[i] + "</li>");
                    }
                    msg.push("</ul>");

                    addErrorBg( "#myMessageModal .modal-header" );
                    setObjHTML( "#myMessageModal h4.modal-title", "Error" );
                    setObjHTML( "#myMessageModal div.modal-body", msg.join('') );
                    jQuery( "#myMessageModal" ).modal();

                }
            }
            else {
                if( data.success ){

                    if(type == 'select' ){

                        var selector = obj + " option";
                        //jQuery(selector).not(':eq(0)').remove();
                        jQuery(selector).remove();
                        for(i=0; i < data.result.length; i++ ){
                            jQuery(obj).append('<option value="'+data.result[i].ref+'">'+ data.result[i].name + '</li>');
                            //console.log(JSON.stringify(data.result[i]));
                        }

                        if(selectType == 'select2'){
                            jQuery(obj).select2({
                                allowClear  : true

                            });
                        }
                        if(selectType == 'multi'){
                            jQuery(obj).multiselect('rebuild');
                        }
                    }

                }
            }



        },

        error: function( xhr, textStatus, errorThrown ){
            hideObject('loading', 'id');
            var msg = "An error occured! "+ ( errorThrown ? errorThrown : xhr.status );
            addErrorBg( "#myMessageModal .modal-header" );
            setObjHTML( "#myMessageModal h4.modal-title", "Error" );
            setObjHTML( "#myMessageModal div.modal-body", msg );
            jQuery( "#myMessageModal" ).modal();
        }
    });

}
