function removePrize( promo, prize ){

    if( prize.length <= 0 || prize.length <= 0 ){

        var msg = "Invalid data provided ";
        addErrorBg( "#myMessageModal .modal-header" );
        setObjHTML( "#myMessageModal h4.modal-title", "Error" );
        setObjHTML( "#myMessageModal div.modal-body", msg );
        jQuery( "#myMessageModal" ).modal();
        return;
    }

    showObject('loading', 'id');
    jQuery.ajax({
        type: "POST",
        timeout: timeoutTime,
        cache: false,
        url: '/promo/prize/remove',
        data: {
            promo  : promo,
            prize  : prize


        },
        success:function( data ){

            console.log(JSON.stringify(data)+" tota");
            hideObject('loading', 'id');
            var msg = [];
            if( data.error_code > 0 ){

                if( data.message.length > 0 ){
                    msg.push("<ul>");
                    for(var i=0; i < data.message.length; i++ ){
                        msg.push("<li>"+ data.message[i] + "</li>");
                    }
                    msg.push("</ul>");

                    addErrorBg( "#myMessageModal .modal-header" );
                    setObjHTML( "#myMessageModal h4.modal-title", "Error" );
                    setObjHTML( "#myMessageModal div.modal-body", msg.join('') );
                    jQuery( "#myMessageModal" ).modal();

                }
            }
            else {
                if(data.success ){

                    addSuccessBg( "#myMessageModal .modal-header" );
                    setObjHTML( "#myMessageModal h4.modal-title", "Success" );
                    setObjHTML( "#myMessageModal div.modal-body", "Brand Package Updated." );
                    jQuery( "#myMessageModal" ).modal();
                    window.location.reload();
                }
            }

        },
        error: function( xhr, textStatus, errorThrown ){
            hideObject('loading', 'id');
            var msg = "An error occured! "+ ( errorThrown ? errorThrown : xhr.status );
            addErrorBg( "#myMessageModal .modal-header" );
            setObjHTML( "#myMessageModal h4.modal-title", "Error" );
            setObjHTML( "#myMessageModal div.modal-body", msg );
            jQuery( "#myMessageModal" ).modal();
        }
    });
}


function publishPrize( promo, distribution ){

    if( distribution.length <= 0 || distribution.length <= 0 ){

        var msg = "Invalid data provided ";
        addErrorBg( "#myMessageModal .modal-header" );
        setObjHTML( "#myMessageModal h4.modal-title", "Error" );
        setObjHTML( "#myMessageModal div.modal-body", msg );
        jQuery( "#myMessageModal" ).modal();
        return;
    }

    hideObject("prize-publication-"+ distribution, "id");
    showObject('loading', 'id');
    jQuery.ajax({
        type: "POST",
        timeout: timeoutTime,
        cache: false,
        url: '/promo/distribution/publish',
        data: {
            promo           : promo,
            distribution    : distribution


        },
        success:function( data ){

            console.log(JSON.stringify(data)+" tota");
            hideObject('loading', 'id');
            var msg = [];
            var obj = "prize-publication-"+ distribution;

            if( data.error_code > 0 ){

                if( data.message.length > 0 ){
                    msg.push("<ul>");
                    for(var i=0; i < data.message.length; i++ ){
                        msg.push("<li>"+ data.message[i] + "</li>");
                    }
                    msg.push("</ul>");

                    addErrorBg( "#myMessageModal .modal-header" );
                    setObjHTML( "#myMessageModal h4.modal-title", "Error" );
                    setObjHTML( "#myMessageModal div.modal-body", msg.join('') );
                    jQuery( "#myMessageModal" ).modal();

                }
            }
            else {
                if(data.success ){

                    addSuccessBg( "#myMessageModal .modal-header" );
                    setObjHTML( "#myMessageModal h4.modal-title", "Success" );
                    setObjHTML( "#myMessageModal div.modal-body", "Brand Package Updated." );
                    jQuery( "#myMessageModal" ).modal();
                    //window.location.reload();

                    var link = "#" + obj + " a";
                    var dot = "#" + obj + " i.fa-circle";
                    if(jQuery(dot).hasClass("red")){
                        jQuery(dot).removeClass("red");
                    }
                    jQuery(dot).addClass("green");
                    jQuery(link).remove();
                }
            }
            showObject("prize-publication-"+ distribution, "id");

        },
        error: function( xhr, textStatus, errorThrown ){
            showObject("prize-publication-"+ distribution, "id");
            hideObject('loading', 'id');
            var msg = "An error occured! "+ ( errorThrown ? errorThrown : xhr.status );
            addErrorBg( "#myMessageModal .modal-header" );
            setObjHTML( "#myMessageModal h4.modal-title", "Error" );
            setObjHTML( "#myMessageModal div.modal-body", msg );
            jQuery( "#myMessageModal" ).modal();
        }
    });
}
