function searchClient( txt ){

    if( txt.length < 3 ){

        /*var msg = "Invalid data provided ";
        addErrorBg( "#myMessageModal .modal-header" );
        setObjHTML( "#myMessageModal h4.modal-title", "Error" );
        setObjHTML( "#myMessageModal div.modal-body", msg );
        jQuery( "#myMessageModal" ).modal();*/
        return;
    }

    showObject('loading', 'id');
    jQuery.ajax({
        type: "POST",
        timeout: timeoutTime,
        cache: false,
        url: '/client/search',
        data: {
            title  : txt


        },
        success:function( data ){

            console.log(JSON.stringify(data)+" tota");
            hideObject('loading', 'id');
            var msg = [];
            var i = 0;
            if( data.error_code > 0 ){

                if( data.message.length > 0 ){
                    msg.push("<ul>");
                    for(i=0; i < data.message.length; i++ ){
                        msg.push("<li>"+ data.message[i] + "</li>");
                    }
                    msg.push("</ul>");

                    addErrorBg( "#myMessageModal .modal-header" );
                    setObjHTML( "#myMessageModal h4.modal-title", "Error" );
                    setObjHTML( "#myMessageModal div.modal-body", msg.join('') );
                    jQuery( "#myMessageModal" ).modal();

                }
            }
            else {
                if(data.success ){

                    jQuery("#search-result").empty();
                    jQuery("#search-result").append("<ul>");
                    for(i=0; i <data.result.length; i++){
                        jQuery("#search-result").append(
                            '<li class="border-bottom-1"><a class="btn-block black" href="/client/'+data.result[i].ref+'">'+data.result[i].name+'</a></li>'
                        );
                    }
                    jQuery("#search-result").append("</ul>");
                    jQuery("#search-result li").click(function(){
                        hideObject("search-result", "id");
                    });
                    showObject("search-result", "id");
                }
            }

        },
        error: function( xhr, textStatus, errorThrown ){
            hideObject('loading', 'id');
            var msg = "An error occured! "+ ( errorThrown ? errorThrown : xhr.status );
            addErrorBg( "#myMessageModal .modal-header" );
            setObjHTML( "#myMessageModal h4.modal-title", "Error" );
            setObjHTML( "#myMessageModal div.modal-body", msg );
            jQuery( "#myMessageModal" ).modal();
        }
    });
}


jQuery(document).ready(function(){
    jQuery(document).mouseup(function (e)
    {
        var container = jQuery("#search-result");

        if (!container.is(e.target) // if the target of the click isn't the container...
            && container.has(e.target).length === 0) // ... nor a descendant of the container
        {
            //container.hide();
            hideObject("search-result", "id");
        }
    });
});
